<template>
  <el-card class="card" shadow="hover">
    <div class="card-banner" @click="onClickTo(item)">
      <img :src="item.img" alt="" />
    </div>
    <div class="card-ft">
      <h3 class="card-ft-title rows-3-ellipsis" @click="onClickTo(item)">
        {{ item.name }}
      </h3>
      <div v-if="price" class="card-control-price notranslate">
        ￥
        <span class="card-control-price-count">{{ item.market_price }}</span>
      </div>
      <div class="card-control">
        <el-input-number v-model="num" :min="1" @change="handleChange" />
        <el-button type="primary" :disabled="disabled" v-loading="disabled" plain @click="onAdd">加入购物车</el-button>
      </div>
    </div>
  </el-card>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { cartCreate } from '@/api/cart.js'
import { useStore } from 'vuex'
import { ElNotification } from 'element-plus'
const props = defineProps({
  item: {
    type: [Object],
    default: () => ({})
  },
  price: {
    type: [Boolean],
    default: false
  }
})
const store = useStore()
const num = ref(1)
const handleChange = (e) => {
  num.value = e
}
const router = useRouter()
const onClickTo = (item) => {
  const { id } = item || {}
  router.push({
    name: 'GoodsDetail',
    query: { id }
  })
}
const disabled = ref(false)
const disabledStatusFalse = (count) => {
  setTimeout(() => {
    disabled.value = false
  }, count * 1000)
}
const onAdd = () => {
  const { id } = props.item
  disabled.value = true
  disabledStatusFalse(60)
  cartCreate({
    goods_alias: id,
    goods_number: num.value
  })
    .then((res) => {
      disabledStatusFalse(1)
      const { error, msg } = res
      if (error == 0) {
        ElNotification({
          title: msg || 'success',
          type: 'success'
        })
        num.value = 1
        store.dispatch('app/getCartList')
      }
    })
    .catch(() => {
      disabledStatusFalse(1)
      num.value = 1
    })
}
</script>

<style lang="scss" scoped>
.card {
  border: none;

  :deep(.el-card__body) {
    padding: 8px;
  }

  &-banner {
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }
  }

  &-ft {
    &-title {
      font-size: 16px;
      line-height: 24px;
      color: #252424;
      height: 72px;
      cursor: pointer;
    }
  }

  &-control {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-price {
      width: 100%;
      font-size: 18px;
      color: var(--el-color-primary);
      margin-bottom: 20px;
      margin-top: -10px;

      &-count {
        font-size: 22px;
        margin-left: -5px;
      }
    }
  }
}

.el-input-number {
  margin-bottom: 10px;
}
</style>
