<template>
  <div class="container pt-3">
    <!-- <div class="title">热销商品</div> -->
    <el-breadcrumb  class="mb-5">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item v-if="title">{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div v-if="!loading && list.length === 0" class="empty">
      <el-empty description="抱歉，暂无数据" />
    </div>
    <div v-else class="wrap">
      <GoodsCard v-for="item in list" :key="item.id" :item="item" />
    </div>
    <!--根据该DOM是否出现在可是区域判断是否滑动底部从而去加载下一页的数据-->
    <div v-loading="loading" class="icon" ref="iconRef">
      {{ lastPage ? '已经到底了' : '' }}
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// import { Loading } from '@element-plus/icons-vue'

import { goodsList } from '@/api/goods'
import GoodsCard from '../sort/components/goodsCard.vue'
import { specialGoodsList, specialView } from '@/api/special.js'

const route = useRoute()
const router = useRouter()

const kw = computed(() => route.query.kw)
const list = ref([])
const loading = ref(false)
const lastPage = ref(false)
const page = ref(1)

const iconRef = ref(null)

const title = ref('')
const specialData = ref({})

onMounted(() => {
  // 建立观察者
  const ob = new IntersectionObserver(
    (entries) => {
      // 返回true代表进入在页面可视区域，false代表不在页面可视区域
      const flag = entries[0].isIntersecting
      if (flag && !loading.value) {
        console.log('entriesentries')
        loadMore()
      }
    },
    {
      thresholds: 0.5
    }
  )

  // 观察元素
  ob.observe(iconRef.value)
})

const init = () => {
  specialView({
    alias: route.query.alias
  }).then((res) => {
    const { data } = res
    specialData.value = data
    // const { title, slide_position_alias } = data
    title.value = data.title
    // if (slide_position_alias) {
    //   const siwpeParams = {
    //     slide_position_alias: slide_position_alias
    //   }
    //   this.initSwipe(siwpeParams)
    // }
  })
}
// const initSwipe = (params) =>  {
//   slideList(params).then((res) => {
//     const { data } = res
//     const { items } = data
//     this.images = items
//   })
// }
init()

const getList = (type) => {
  loading.value = true
  specialGoodsList({
    alias: route.query.alias,
    page: page.value,
    pageSize: 20
  })
  // goodsList({ page: page.value, pageSize: 20, is_best: 1 })
    .then((res) => {
      if (type === 'load') {
        list.value.push(...res.data.items)
      } else {
        list.value = res.data.items
      }

      loading.value = false
      lastPage.value = res.data.lastPage
    })
    .catch(() => {
      loading.value = false
    })
}

// const goDetail = (cell) => {
//   const { id } = cell
//   router.push({ name: 'GoodsDetail', query: { id } })
// }

const loadMore = () => {
  if (lastPage.value) return
  page.value += 1
  getList('load')
}

watch(kw, () => {
  page.value = 1
  getList('init')
})

getList('init')
</script>

<style lang="scss" scoped>
.container {
  & > .title {
    margin-top: 30px;
    margin-bottom: 50px;
    text-align: center;
    font-size: 44px;
    font-weight: 500;
    color: #000000;
    line-height: 62px;
  }
  .empty {
    text-align: center;
  }

  .wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    .goods-item {
      cursor: pointer;
      .carousel-item-banner {
        height: 236px;
        overflow: hidden;
        margin-bottom: 17px;
      }
    }
  }

  .icon {
    text-align: center;
    font-size: 16px;
    color: #999;
    height: 80px;
    line-height: 80px;
    :deep(.el-loading-spinner) {
      top: 22%;
    }
  }
}
.el-breadcrumb {
  &__item {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    line-height: 25px;
  }
}

</style>
