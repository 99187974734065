import request from '@/utils/request'

/**
 * 查看专题
 */
export function specialView(params) {
  return request({
    url: '/special/view',
    method: 'get',
    params
  })
}

/**
 * 查看专题商品列表
 */
export function specialGoodsList(params) {
  return request({
    url: '/special/goods-list',
    method: 'get',
    params
  })
}
